import React, { useState, useEffect } from 'react';
import { ReceitaPageContainer } from './styles.js';

import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import Button from '../../components/Button';
import moment from 'moment';
import { update as updateReceita } from '../../services/ReceitaService';
import { useDispatch } from 'react-redux';
import {
  clearMedicamentos,
  fetchModeloReceitaById,
  setReceberOrcamento,
} from '../../stores/newReceitaActions';
import { sendEmail } from '../../services/SendEmailReceitaService';
import Receita from './Receita';

function getIdade(dataNascimento) {
  return moment().diff(dataNascimento, 'years') || 0;
}

function getPacienteInitialText(paciente, receita) {
  const pacienteInfoText = `${
    receita ? 
    `Prontuário: ${receita.rec_in_codigo} \n` : '\n'
  }${paciente?.pac_st_nome ? 
    `Nome: ${paciente.pac_st_nome} \n` : ''}${
    paciente?.pac_st_telefone ? 
    `Telefone: ${paciente.pac_st_telefone}` : ''
  }
    ${
      paciente?.sexo
        ? `Sexo: ${
            paciente.sexo === 'M'
              ? 'Masculino'
              : paciente.sexo === 'F'
              ? 'Feminino'
              : 'Prefiro não informar'
          }`
        : ''
    }
    ${
      paciente?.dataNascimento
        ? `Data de nascimento: ${moment(paciente.dataNascimento).format(
            'DD/MM/YYYY'
          )}`
        : ''
    }    ${
    paciente?.dataNascimento
      ? `Idade: ${getIdade(paciente.dataNascimento)} ANOS`
      : ''
  }`;

  return pacienteInfoText;
}

// function getMedicamentosInitialText(medicamentos, vias) {
//   let viasArray = [];

//   vias.map((via) => {
//     const viaId = via.via_in_codigo;
//     const fullVia = via;
//     medicamentos.map((med) => {
//       // console.log("array: ", viasArray);
//       // console.log("id pai e med: ", viaId, " ", med.via);
//       // console.log("id med e include: ", med.via, viasArray.includes(fullVia));
//       const medId = med.via;
//       const idMed = parseInt(medId);
//       if (idMed === viaId) {
//         viasArray.includes(fullVia)
//           ? console.log('ja tem')
//           : viasArray.push(fullVia);
//         console.log('adicionado');
//       }
//     });
//   });

//   // console.log("vias array: ", viasArray);
//   // console.log("funcao text meds: ", medicamentos, " e vias: ", vias);

//   let medicamentosInfoText = '';

//   viasArray.map((via) => {
//     const viaPai = via;
//     // medicamentosInfoText +=
//     //   viaPai.via_st_nome +
//     //   ` --------------------------------------------------------------------------- \n`;
//     medicamentos.map((medicamento, count) => {
//       console.log('medicamento no map: ', medicamento);
//       if (medicamento.vias == viaPai.via_in_codigo) {
//         if (medicamento.resumo)
//           medicamentosInfoText +=
//             count + 1 + ' - '  + medicamento.resumo + '\n\n';
//         else {
//           medicamentosInfoText +=
//             count +
//             1 +
//             ' - ' +
//             medicamento.nome +
//             '............................................................................................ \n' +
//             +(medicamento.veiculoUtilizacao || '') +
//             ' ' +
//             (medicamento.quantidadeVeiculo || '') +
//             ' ' +
//             (medicamento.unidade || '') +
//             // "\n" +
//             (medicamento.posologia || '') +
//             '\n' +
//             (medicamento.observacao || '') +
//             '\n';
//         }
//         // console.log("txt no if: ", medicamentosInfoText)
//       } else {
//         // console.log("txt no else: ", medicamentosInfoText)
//         return null;
//       }
//     });
//   });
//   // medicamentos.forEach((medicamento, count) => {
//   //   if (medicamento.resumo) medicamentosInfoText += (count + 1) + ' - ' + medicamento.resumo + '\n\n';
//   //   else {
//   //     medicamentosInfoText += (count + 1) + ' - ' + medicamento.nome +
//   //       '............................................................................................' +
//   //       + (medicamento.veiculoUtilizacao || '') + ' ' + (medicamento.quantidadeVeiculo || '') + ' ' + (medicamento.unidade || '') + '\n'
//   //       + (medicamento.posologia || '') + '\n'
//   //       + (medicamento.observacao || '') + '\n'
//   //   }
//   // });

//   return medicamentosInfoText;
// }

function getViaName(viaId, vias) {
  for (var i = 0; i < vias.length; i++) {
    if (vias[i].via_in_codigo == viaId) {
      return vias[i].via_st_nome;
    }
  }
}

function getMedicamentosInitialText(medicamentos, vias) {
  console.log('getting here2: ', medicamentos);
  let medicamentosInfoText = '';
  let viaName = '';
  medicamentos.forEach((medicamento, count) => {
    if (medicamento.via) {
      viaName = getViaName(medicamento.via, vias);
    }
    if (medicamento.resumo)
      medicamentosInfoText += count + 1 + ' - ' + medicamento.resumo + '\n\n';
    else {
      medicamentosInfoText +=
        count +
        1 +
        ' - ' +
        medicamento.nome +
        '............................................................................................' +
        +(medicamento.veiculoUtilizacao || '') +
        ' ' +
        (medicamento.quantidadeVeiculo || '') +
        ' ' +
        (medicamento.unidade || '') +
        '\n' +
        (medicamento.posologia || '') +
        '\n' +
        (medicamento.observacao || '') +
        '\n' +
        viaName +
        '\n';
    }
  });

  return medicamentosInfoText;
}

export default function NewReceitaForm() {
  const receita = useSelector((state) => state.receita.new.receita);
  const medicamentos = useSelector((state) => state.receita.new.medicamentos);
  const paciente = useSelector((state) => state.receita.new.paciente);
  const receberOracemnto = useSelector((state) => state.receita.new.orcamento);
  const usuario = useSelector((state) => state.auth.user);

  const history = useHistory();

  const dispatch = useDispatch();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const modelo = useSelector((state) => state.receita.new.modelo);

  const vias = useSelector((state) => state.vias.vias);

  const [pacienteFieldValue, setPacienteFieldValue] = useState('');
  const [medicamentosFieldValue, setMedicamentosFieldValue] = useState('');

  const [isFirst, setFirst] = useState(true);
  const [modificado, setModificado] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (!receita) return;
    if (medicamentos.length === 0) return;

    // paciente
    const pacienteInfoText = getPacienteInitialText(paciente, receita);

    // medicamentos
    const medicamentosInfoText = getMedicamentosInitialText(medicamentos, vias);

    console.log('paciente info text: ', pacienteInfoText);
    setPacienteFieldValue(pacienteInfoText);
    setMedicamentosFieldValue(medicamentosInfoText);

    dispatch(fetchModeloReceitaById(farmaciaId, usuario.rem_in_codigo));
  }, []);

  // set modificado
  useEffect(() => {
    if (isFirst) return;
    else setFirst(false);

    if (modificado) return;

    const pacienteTextInitialValue = getPacienteInitialText(paciente, receita);
    const medicamentoTextInitialValue = getMedicamentosInitialText(
      medicamentos,
      vias
    );

    if (pacienteTextInitialValue !== pacienteFieldValue) setModificado(true);
    if (medicamentoTextInitialValue !== medicamentosFieldValue)
      setModificado(true);
  }, [pacienteFieldValue, medicamentosFieldValue]);

  if (!receita) return <Redirect to='/receita' />;
  if (medicamentos.length === 0) return <Redirect to='/receita' />;

  function buildTxtReceita() {
    const plainTextReceita = `
${pacienteFieldValue}

Medicamentos:
${medicamentosFieldValue}`;

    return plainTextReceita;
  }

  async function sendEmailReceita() {
    console.log('inicio send email');

    try {
      // e-mail already sent, no need to send again
      if (isEmailSent) return;

      // paciente does not allow contact
      if (!receberOracemnto) return;

      const txtReceita = buildTxtReceita();

      await sendEmail(farmaciaId, usuario.usu_in_codigo, {
        rec_in_codigo: receita.rec_in_codigo,
        rec_st_nomepaciente: paciente.pac_st_nome,
        rec_st_telefonepaciente: paciente.pac_st_telefone,
        rec_st_emailpaciente: paciente.pac_st_emailcontato,
        rec_st_txtreceita: txtReceita,
      });

      setEmailSent(true);

      dispatch(setReceberOrcamento(false));
    } catch (error) {
    }
  }

  async function saveReceita() {
    const txtReceita = buildTxtReceita();

    // update receita text
    await updateReceita(
      farmaciaId,
      usuario.usu_in_codigo,
      receita.rec_in_codigo,
      {
        rec_st_txtreceita: txtReceita,
      }
    );
  }

  function ButtonPrint() {
    return (
      <Button
        color='secondary'
        size='sm'
        className='button-print'
        handleclick={() => {
          window.print();

          sendEmailReceita();
        }}
      >
        Imprimir
      </Button>
    );
  }

  function ButtonEnd() {
    return (
      <Button
        size='sm'
        className='ml-1 button-print'
        handleclick={() => {
          saveReceita();

          sendEmailReceita();

          dispatch(clearMedicamentos());

          history.push('/');
        }}
      >
        Finalizar
      </Button>
    );
  }

  return (
    <ReceitaPageContainer>
      <Title
        hideButton
        component={() => [<ButtonPrint key={1} />, <ButtonEnd key={2} />]}
      />

      <Receita
        modelo={modelo}
        pacienteFieldValue={pacienteFieldValue}
        setPacienteFieldValue={setPacienteFieldValue}
        medicamentosFieldValue={medicamentosFieldValue}
        setMedicamentosFieldValue={setMedicamentosFieldValue}
      />
    </ReceitaPageContainer>
  );
}
