import React, { useEffect, useState } from 'react';
import {
  clearAll,
  setIndustrializados,
  setPage,
  setTotalItems,
} from '../../stores/representanteIndustrializadosListActions';
import { useDispatch, useSelector } from 'react-redux';

import ButtonAdd from '../../components/ButtonAdd';
import Grid from '../../components/Grid';
import MedicamentoList from '../MedicamentoListContainer/MedicamentoList';
import Pagination from '../../components/Pagination';
import Row from '../../components/Row';
import Skeleton from 'react-loading-skeleton';
import Title from '../../components/Title';
import { findIndustrializadosByRepresentante } from '../../services/RepresentanteMedicamentosService';
import { useHistory } from 'react-router-dom';

export default function MedicamentoRepresentanteListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const industrializados = useSelector(
    (state) => state.representante.industrializado.industrializados
  );
  const usuarioId = useSelector((state) => state.auth.user.usu_in_codigo);

  // pagination
  const page = useSelector(
    (state) => state.representante.industrializado.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.representante.industrializado.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.representante.industrializado.pagination.totalItems
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  async function findIndustrializados(page, itemsPerPage) {
    const response = await findIndustrializadosByRepresentante(
      farmaciaId,
      usuarioId,
      { page, itemsPerPage }
    );

    dispatch([
      setIndustrializados(response.data),
      setTotalItems(response['_metadata'].totalItems),
    ]);
  }

  useEffect(() => {
    findIndustrializados(1, itemsPerPage);

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [industrializados]);

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch(setPage(pgNumber));

    findIndustrializados(pgNumber, itemsPerPage);
  }

  return (
    <div className='industrializado-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd
            key={3}
            onClick={() => history.push('/medicamentos/new')}
          />
        )}
      >
        Listagem de industrializados
      </Title>
      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <MedicamentoList medicamentos={industrializados} readonly />
          )}
        </Grid>
      </Row>

      {industrializados.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
