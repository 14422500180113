import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllEspecialidadesByFarmacia,
  setSelectedEspecialidade,
  searchMedicamentos,
  fetchLinksEspecialidadeMedicamentos,
  // searchMedicamentos,
  // setselectedEspecialidade,
  // fetchLinksMedicoMedicamentos,
  setRef,
  // setTipo,
  setPage,
  clearAll,
} from '../../stores/linkEspeciliadadeMedicamentosActions';
import Title from '../../components/Title';
import EspecialidadesList from './EspecialidadesList';
import Skeleton from 'react-loading-skeleton';
import MedicamentoList from './MedicamentoList';
import {
  linkEspecialidadeToAtivo,
  unlinkEspecialidadeAtivo,
  linkEspecialidadeToFormula,
  unlinkEspecialidadeFormula,
  linkEspecialidadeToIndustrializado,
  unlinkEspecialidadeIndustrializado,
} from '../../services/LinkMedicamentosToEspecialidadeService';
import {
  linkAtivoToMedico,
  unlinkAtivoMedico,
  linkFormulaMedico,
  unlinkFormulaMedico,
  linkIndustrializadoToMedico,
  unlinkIndustrializadoMedico,
  linkMultipleToMedicoByFilter,
} from '../../services/LinkMedicoToMedicamentoService';
import MedicamentoSearch from './MedicamentoSearch';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLoading as loading } from '../../stores/loadingActions';
import EspecialidadeSearch from './EspecialidadeSearch';

export default function LinkMedicamentoToMedicoContainer() {
  const dispatch = useDispatch();
  // search
  const ref = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.search.ref
  );
  const tipo = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.search.tipo
  );

  // pagination
  const page = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.pagination.totalItems
  );

  const selectedEspecialidade = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.selectedEspecialidade
  );

  const history = useHistory();

  // medicamentos
  const results = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.allMedicamentos
  );

  // medicamentos linked to selected medico
  const linksMedicamentosEspecialidades = useSelector(
    (state) =>
      state.linkEspecialidadeMedicamentos.linksMedicamentosEspecialidades
  );

  const medicamentos = results.map((result) => {
    const isLinked = linksMedicamentosEspecialidades.find((link) => {
      // ativo
      if (result.codigo === link.atv_in_codigo && result.tipo === 'ATIVO')
        return true;

      // formula
      if (result.codigo === link.for_in_codigo && result.tipo === 'FORMULA')
        return true;

      // industrializado
      if (
        result.codigo === link.med_in_codigo &&
        result.tipo === 'INDUSTRIALIZADO'
      )
        return true;

      return false;
    });

    if (isLinked) return { ...result, linked: true };
    else return result;
  });

  const especialidades = useSelector(
    (state) => state.linkEspecialidadeMedicamentos.allEspecialidades
  );

  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const [refEspec, setRefEspec] = useState('');

  const [step, setStep] = useState(1);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllEspecialidadesByFarmacia(farmaciaId));

    return () => dispatch(clearAll());
  }, []);

  // loading medicos
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [results, especialidades]);

  useEffect(() => {
    dispatch(
      searchMedicamentos(
        farmaciaId,
        { ref: '', activeOnly: true, excludeExclusive: false },
        { page: 1, itemsPerPage }
      )
    );
  }, []);

  function handleSelectEspecialidade(especialidade) {
    setLoading(true);

    dispatch([
      loading(true),
      setSelectedEspecialidade(especialidade),
      searchMedicamentos(
        farmaciaId,
        { ref: '', activeOnly: true, excludeExclusive: false },
        { page: 1, itemsPerPage }
      ),
      fetchLinksEspecialidadeMedicamentos(
        farmaciaId,
        especialidade.esp_in_codigo
      ),
    ]);

    setStep(2);
  }

  async function handleLinkMedicamento(medicamento) {
    dispatch(loading(true));

    if (medicamento.tipo === 'ATIVO') {
      await linkEspecialidadeToAtivo(
        farmaciaId,
        medicamento.codigo,
        selectedEspecialidade.esp_in_codigo
      );
    }

    if (medicamento.tipo === 'FORMULA') {
      await linkEspecialidadeToFormula(
        farmaciaId,
        medicamento.codigo,
        selectedEspecialidade.esp_in_codigo
      );
    }

    if (medicamento.tipo === 'INDUSTRIALIZADO') {
      await linkEspecialidadeToIndustrializado(
        farmaciaId,
        medicamento.codigo,
        selectedEspecialidade.esp_in_codigo
      );
    }

    dispatch(
      fetchLinksEspecialidadeMedicamentos(
        farmaciaId,
        selectedEspecialidade.esp_in_codigo
      )
    );
  }

  async function handleUnlinkMedicamento(medicamento) {
    dispatch(loading(true));

    if (medicamento.tipo === 'ATIVO') {
      await unlinkEspecialidadeAtivo(
        farmaciaId,
        medicamento.codigo,
        selectedEspecialidade.esp_in_codigo
      );
    }

    if (medicamento.tipo === 'FORMULA') {
      await unlinkEspecialidadeFormula(
        farmaciaId,
        medicamento.codigo,
        selectedEspecialidade.esp_in_codigo
      );
    }

    if (medicamento.tipo === 'INDUSTRIALIZADO') {
      await unlinkEspecialidadeIndustrializado(
        farmaciaId,
        medicamento.codigo,
        selectedEspecialidade.esp_in_codigo
      );
    }

    dispatch(
      fetchLinksEspecialidadeMedicamentos(
        farmaciaId,
        selectedEspecialidade.esp_in_codigo
      )
    );
  }

  function handlePaginate(page) {
    setLoading(true);

    dispatch([
      setPage(page),
      searchMedicamentos(
        farmaciaId,
        { ref, tipo, activeOnly: true, excludeExclusive: true },
        { page, itemsPerPage }
      ),
    ]);
  }

  function handleSearchMeds({ ref, tipo }) {
    setLoading(true);

    dispatch([
      setPage(1),
      searchMedicamentos(
        farmaciaId,
        { ref, tipo, activeOnly: true, excludeExclusive: true },
        { page: 1, itemsPerPage }
      ),
      setRef(ref),
    ]);
  }

  function handleClearSearchMeds() {
    setLoading(true);

    dispatch([
      setPage(1),
      searchMedicamentos(
        farmaciaId,
        { ref: '', activeOnly: true, excludeExclusive: true },
        { page: 1, itemsPerPage }
      ),
      setRef(''),
      // setTipo(null),
    ]);
  }

  function goBack() {
    if (step === 2) setStep(1);
    if (step === 1) history.push('/');
  }

  function handleSearch({ ref }) {
    setLoading(true);

    setRefEspec(ref);
  }

  function handleClearSearch() {
    setLoading(true);

    setRefEspec('');
  }

  return (
    <div className='link-container'>
      <Title onClick={goBack}>Vinculo especialidades - medicamento</Title>

      {
        {
          1: (
            <div>
              <p>Selecione o médico:</p>

              <EspecialidadeSearch
                onSubmit={handleSearch}
                onClear={handleClearSearch}
              />
              {isLoading ? (
                <Skeleton count={3} height={60} width={200} />
              ) : (
                <EspecialidadesList
                  onSelectEspecialidade={handleSelectEspecialidade}
                  especialidades={especialidades}
                  search={refEspec}
                />
              )}
            </div>
          ),

          2: (
            <div>
              <MedicamentoSearch
                onSubmit={handleSearchMeds}
                onClear={handleClearSearchMeds}
              />

              {isLoading ? (
                <Skeleton count={3} height={60} width={200} />
              ) : (
                <MedicamentoList
                  results={medicamentos}
                  onLinkMedicamento={handleLinkMedicamento}
                  onUnlinkMedicamento={handleUnlinkMedicamento}
                />
              )}

              {medicamentos.length === 0 ? null : (
                <Pagination
                  page={page}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  paginate={handlePaginate}
                />
              )}
            </div>
          ),
        }[step]
      }
    </div>
  );
}
